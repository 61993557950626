import { OrganizationContext } from "contexts/OrganizationProvider";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { put } from "utils/DeApi";

const useBulkUpdateActionItems = ({ selectedItems, onActionItemsUpdated }) => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const bulkUpdateActionItems = (values) => {
    const { assignedTo, dueDate } = values;

    setError("");
    setIsLoading(true);
    const actionItemsUpdatePromise = put(
      `organizations/${organization?.id}/action-items-bulk-update`,
      {
        actionItemIds: selectedItems
          .filter(({ status }) => status !== 3)
          .map(({ actionItemId }) => actionItemId),
        assignedTo: assignedTo?.length
          ? assignedTo.map(({ subscriberId }) => subscriberId)
          : [],
        ...(dueDate
          ? { dueDate: moment(dueDate).format("YYYY-MM-DD HH:mm:ss") }
          : {}),
      }
    );

    actionItemsUpdatePromise.promise
      .then((response) => {
        setIsLoading(false);
        onActionItemsUpdated(response.data);
      })
      .catch((error) => {
        console.error(error);
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, []);

  return {
    error,
    isLoading,
    setError,
    bulkUpdateActionItems,
  };
};

export default useBulkUpdateActionItems;
