import { isArray } from "lodash";
import {
  RISK,
  NOTES,
  COMPLIANCE,
  CASUAL_FACTOR,
  RECOMMENDATION,
  CONFORMITY_LEVEL,
  CORRECTIVE_ACTION,
} from "features/assessment/constants/observationProps";
import { COMPLIANCE_INAPPLICABLE_REASONS } from "../constants/observationProps";

/**
 * @param {conformity} []
 * @param {options} [{ id: 0, value: true, name: 'Risk' || false}, {} ...]
 * @returns
 */

const useObservationVisibility = ({ conformity, options }) => {
  const fieldOptions = Array.isArray(options) ? options : [];

  const showRisk = () => {
    if (!isArray(conformity) || !isArray(options)) return false;
    if (options.every(({ value }) => value === false)) return false;
    return !!options.find(({ id, value }) => parseInt(id) === RISK && value);
  };

  const showCompliance = () => {
    if (!isArray(conformity) || !isArray(options)) return false;
    if (options.every(({ value }) => value === false)) return false;
    return !!options.find(
      ({ id, value }) => parseInt(id) === COMPLIANCE && value
    );
  };

  const showConformityLevel = () => {
    if (!isArray(conformity) || !isArray(options)) return false;
    return !!options.find(
      ({ id, value }) => parseInt(id) === CONFORMITY_LEVEL && value
    );
  };

  const showConformityInapplicableReasons = () => {
    if (!isArray(conformity) || !isArray(options)) return false;
    return !!options.find(
      ({ id, value }) => parseInt(id) === CONFORMITY_LEVEL && value
    );
  };

  const showNotes = () => {
    if (!isArray(conformity) || !isArray(options)) return false;
    if (options.every(({ value }) => value === false)) return false;
    return !!options.find(({ id, value }) => parseInt(id) === NOTES && value);
  };

  const showRecommendation = () => {
    if (!isArray(conformity) || !isArray(options)) return false;
    if (options.every(({ value }) => value === false)) return false;
    return !!options.find(
      ({ id, value }) => parseInt(id) === RECOMMENDATION && value
    );
  };

  const showCasualFactor = () => {
    if (!isArray(conformity) || !isArray(options)) return false;
    return !!options.find(
      ({ id, value }) => parseInt(id) === CASUAL_FACTOR && value
    );
  };

  const showCorrectiveAction = () => {
    if (!isArray(conformity) && !isArray(options)) return false;
    if (options.every(({ value }) => value === false)) return false;
    return !!options.find(
      ({ id, value }) => parseInt(id) === CORRECTIVE_ACTION && value
    );
  };

  return {
    showRisk,
    showNotes,
    showCompliance,
    showCasualFactor,
    showRecommendation,
    showConformityLevel,
    showCorrectiveAction,
    showConformityInapplicableReasons,
    fieldLabels: {
      risk: fieldOptions[RISK]?.name || "Risk",
      notes: fieldOptions[NOTES]?.name || "Notes",
      compliance: fieldOptions[COMPLIANCE]?.name || "Compliance",
      casualFactor: fieldOptions[CASUAL_FACTOR]?.name || "Causal Factors",
      recommendation: fieldOptions[CASUAL_FACTOR]?.name || "Recommendation",
      conformityLevel:
        fieldOptions[CONFORMITY_LEVEL]?.name || "Conformity Level",
      correctiveAction:
        fieldOptions[CORRECTIVE_ACTION]?.name || "Corrective Action",
      conformityInapplicableReasons:
        fieldOptions[COMPLIANCE_INAPPLICABLE_REASONS]?.name ||
        "Conformity Inapplicable Reasons",
    },
  };
};

export default useObservationVisibility;
