import { useContext } from "react";
import { ReportContext } from "../contexts/ReportProvider";

const useChartsConfig = () => {
  const { chartState } = useContext(ReportContext);
  const {
    riskPieChart,
    conformityPieChart,
    compliancePieChart,
    dynamicPieChart,
    setDynamicPieChart,
    chartsToBeToggledForExports,
    setChartsToBeToggledForExports,
    combinedConformityPieChart,
    riskPrincipleBarChart,
    conformityPrincipleBarChart,
    PrinciplesScoresGuageChart,
    spiderWebChart,
  } = chartState;

  const hideForClientWorks = (protocolId) => {
    // Contractor Safety Audit (USA) NEW - 64ef9dd38695e
    // Contractor Safety Audit (UK) NEW - 64f78de719879
    // Contractor Safety Audit (Germany) NEW - 64f795d8407c0
    // Contractor Safety Audit (USA) (Retired) - 6494b8fed7a9f
    // Contractor Safety Audit (UK) (Retired) - 64c1319579b81
    // Contractor Safety Audit (Germany) (Retired) - 64c911a935e8b
    return [
      "64ef9dd38695e",
      "64f78de719879",
      "64f795d8407c0",
      "6494b8fed7a9f",
      "64c1319579b81",
      "64c911a935e8b",
    ].includes(protocolId);
  };

  const hideConformityChart = (protocolId) => {
    // Contractor Safety Audit (USA) NEW - 64ef9dd38695e
    // Contractor Safety Audit (UK) NEW - 64f78de719879
    // Contractor Safety Audit (Germany) NEW - 64f795d8407c0
    return ["64ef9dd38695e", "64f78de719879", "64f795d8407c0"].includes(
      protocolId
    );
  };

  const hideBarCharts = (protocolId) => {
    // Contractor Safety Audit (USA) (Retired) - 6494b8fed7a9f
    // Contractor Safety Audit (UK) (Retired) - 64c1319579b81
    // Contractor Safety Audit (Germany) (Retired) - 64c911a935e8b
    return ["6494b8fed7a9f", "64c1319579b81", "64c911a935e8b"].includes(
      protocolId
    );
  };

  const principleGuageCharts = PrinciplesScoresGuageChart?.reduce(
    (acc, chartData) => {
      const title = chartData.observationTitle;
      if (!acc[title]) {
        acc[title] = [];
      }
      acc[title].push(chartData);
      return acc;
    },
    {}
  );

  const handleChartsToBeToggledForExports = (field = {}) => {
    setDynamicPieChart((prevDynamicPieChart) =>
      prevDynamicPieChart.filter(({ id = "" }) => id !== field?.id)
    );

    setChartsToBeToggledForExports((prevChartsToBeToggledForExports) => [
      ...prevChartsToBeToggledForExports,
      field,
    ]);
  };

  const handleResetChartsToBeToggledForExports = () => {
    setDynamicPieChart((prevDynamicPieChart) => {
      const updateDynamicPieChart = [
        ...prevDynamicPieChart,
        ...chartsToBeToggledForExports,
      ]
        .filter(
          (elem, index, self) =>
            self.findIndex((_elem) => _elem === elem) === index
        )
        .sort((a, b) => a?.order - b?.order);

      return [...updateDynamicPieChart];
    });

    setChartsToBeToggledForExports((prev) => []);
  };

  const getChartData = (riskLabel, complianceLabel, conformityLabel) => {
    return {
      charts: [
        riskPieChart?.currentSrc && {
          title: `Distribution of Findings by ${
            riskLabel?.name || `Risk Rating`
          }`,
          image_string: riskPieChart?.currentSrc,
          type: "pie",
        },
        compliancePieChart?.currentSrc && {
          title: `Distribution of Findings by ${
            complianceLabel?.name || "Compliance"
          }`,
          image_string: compliancePieChart?.currentSrc,
          type: "pie",
        },
        conformityPieChart?.currentSrc && {
          title: `Distribution of Findings by
            ${conformityLabel?.name || "Conformity Level"}`,
          image_string: conformityPieChart?.currentSrc,
          type: "pie",
        },
        riskPrincipleBarChart?.src && {
          title: `Distribution of Findings Across Principles and
            ${riskLabel?.name || "Risk Rating"}`,
          image_string: riskPrincipleBarChart?.src,
          type: "bar",
        },
        conformityPrincipleBarChart?.currentSrc && {
          title: `Distribution of Findings across Principles and ${
            conformityLabel?.name || "Conformity Level"
          }`,
          image_string: conformityPrincipleBarChart?.currentSrc,
          type: "bar",
        },
        spiderWebChart?.currentSrc && {
          title: `Scores By Principles`,
          image_string: spiderWebChart?.currentSrc,
          type: "spider",
        },

        ...(Array.isArray(Object.keys(principleGuageCharts)) &&
        PrinciplesScoresGuageChart?.length
          ? Object.keys(principleGuageCharts).flatMap((observationTitle) =>
              principleGuageCharts[observationTitle].map((chartData) => {
                return {
                  title: `Scores By Principles - ${observationTitle}`,
                  image_string: chartData?.img,
                  type: "gauge",
                };
              })
            )
          : []),
        ...(Array.isArray(dynamicPieChart)
          ? dynamicPieChart.map(({ title, chart }) => {
              return {
                title: title,
                image_string: chart?.currentSrc,
                type: "pie",
              };
            })
          : []),
        combinedConformityPieChart?.currentSrc && {
          title: `Distribution of Findings by Conformity Level`,
          image_string: combinedConformityPieChart?.currentSrc,
          type: "pie",
        },
      ].filter(Boolean),
    };
  };

  return {
    hideForClientWorks,
    hideConformityChart,
    hideBarCharts,
    principleGuageCharts,
    handleChartsToBeToggledForExports,
    handleResetChartsToBeToggledForExports,
    getChartData,
  };
};

export default useChartsConfig;
