import { useContext, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";
import { compressImage } from "utils/UploadUtils";
import { ReportContext } from "../contexts/ReportProvider";
import { useToast } from "hooks";
import { UNSUPPORTED_IMAGE_FORMATS } from "../constants";

const useAssessmentLevelCompressImages = ({ files = [] }) => {
  const [compressedImages, setCompressedImages] = useState({});
  const subscribedPromises = useRef([]);
  const { state: { showAttachments } = {} } = useContext(ReportContext);
  const toast = useToast();
  useEffect(() => {
    const compressImagesFromFiles = async () => {
      try {
        if (files.length > 0 && showAttachments) {
          await Promise.all(
            files.map(async ({ url, fileId }) => {
              const imagePromise = await get(url, { responseType: "blob" });
              subscribedPromises.current.push(imagePromise);
              const response = await imagePromise.promise;
              const { type } = response;
              const isUnsupportedImageFormat = !UNSUPPORTED_IMAGE_FORMATS.some(
                (format) => type.startsWith(format)
              );

              if (type.startsWith("image/") && isUnsupportedImageFormat) {
                const compressedBlob = await compressImage(response, 800, 0.1);
                const base64Image = await new Promise((resolve) => {
                  const reader = new FileReader();
                  reader.onload = () => resolve(reader.result);
                  reader.readAsDataURL(compressedBlob);
                });

                setCompressedImages((prevImages) => ({
                  ...prevImages,
                  [fileId]: base64Image,
                }));
              }
            })
          );
        }
      } catch (error) {
        toast.error("Error", `Failed to compress image ${error}`);
      }
    };

    compressImagesFromFiles();
  }, [files, showAttachments]);

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  return { compressedImages };
};

export default useAssessmentLevelCompressImages;
