import { OrganizationContext } from "contexts/OrganizationProvider";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { post } from "utils/DeApi";

const useFetchPromptAnswers = () => {
  const organization = useContext(OrganizationContext);
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [promptAnswers, setPromptAnswers] = useState([]);

  const fetchPromptAnswers = useCallback(
    (prompt, id, lastItem) => {
      setError(null);
      setIsLoading(true);

      const promptPromise = post(
        `ml/organizations/${organization?.id}/files/query`,
        {
          query: prompt,
        }
      );

      promptPromise.promise
        .then(({ data }) => {
          if (!id || !lastItem) {
            return setPromptAnswers((prev) => [
              ...prev,
              { ...data, query: prompt, id: Date.now() },
            ]);
          }
          setPromptAnswers((prev) =>
            prev.map((item) => (item.id === id ? { ...item, ...data } : item))
          );
        })
        .catch((error) => !error.isCanceled && setError(error))
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(promptPromise);
    },
    [organization?.id]
  );

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [fetchPromptAnswers]);

  return {
    isLoading,
    error,
    promptAnswers,
    setIsLoading,
    setError,
    setPromptAnswers,
    fetchPromptAnswers,
  };
};

export default useFetchPromptAnswers;
