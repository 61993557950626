import { useContext, useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";
import { AuditContext } from "../contexts/AuditProvider";
import { ReportContext } from "../contexts/ReportProvider";

const useFetchSummaryTable = () => {
  const { audit } = useContext(AuditContext);
  const {
    state: { showInapplicable, summaryTable: showSummaryTable },
  } = useContext(ReportContext);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [summaryTable, setSummaryTable] = useState();
  const [isSummaryTableEmpty, setIsSummaryTableEmpty] = useState();
  const subscribedPromises = useRef([]);

  useEffect(() => {
    const fetchSummaryTable = () => {
      setIsLoading(true);
      setError(null);

      const summaryTablePromise = get(
        `audits/${audit.auditId}/summary-tables`,
        {
          params: { showInapplicable: showInapplicable ? 1 : 0 },
        }
      );
      summaryTablePromise.promise
        .then(({ data }) => {
          const { dynamicQuestions: { summary = [], total = [] } = {} } =
            data || {};

          const dynamicQuestions = summary.concat(total);

          setIsSummaryTableEmpty(!!dynamicQuestions.length);
          setSummaryTable(data);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
      subscribedPromises.current.push(summaryTablePromise);
    };
    if (showSummaryTable) {
      fetchSummaryTable();
    }
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(function (promise) {
        promise.cancel();
      });
    };
  }, [
    audit.auditId,
    showInapplicable,
    audit.responses,
    audit.observations,
    showSummaryTable,
  ]);

  return { error, isLoading, summaryTable, isSummaryTableEmpty };
};

export default useFetchSummaryTable;
