import { formatFileNames } from "features/files/utils/formatFileNames";
import React, { Fragment, useEffect } from "react";
import FilesAiInsightCard from "../FilesAiInsightCard";
import { useCopyToClipboard, useToast } from "hooks";
import Markdown from "react-markdown";

const FilesAiInsightsContentList = ({
  promptAnswers,
  fetchPromptAnswers,
  setQuery,
}) => {
  const toast = useToast();
  const { handleCopy } = useCopyToClipboard();

  const handleScrollToBottom = () => {
    const offcanvasBody = document.querySelector(".offcanvas-body");
    if (!offcanvasBody) return;
    offcanvasBody.scrollTop = offcanvasBody.scrollHeight;
  };

  useEffect(() => {
    if (promptAnswers?.length) handleScrollToBottom();
  }, [promptAnswers?.length]);

  return (
    !!promptAnswers?.length &&
    promptAnswers.map(({ query, summary, documents, id }, ind) => {
      const files =
        !!documents.length &&
        documents.map(({ metadata: { file_name } }) => file_name);
      const formattedFiles = formatFileNames(files || []);
      const lastItem = ind === promptAnswers.length - 1;
      const fileCountDescriptor = files?.length === 1 ? "this" : "these";
      return (
        <Fragment key={id}>
          <FilesAiInsightCard query={query}>
            <div className="px-3 py-2 rounded fs-6 bg-insights text-break text-dark mb-3">
              {!!documents?.length && (
                <p>
                  {`Summary is based on ${fileCountDescriptor} files`}{" "}
                  <strong>{formattedFiles}</strong>
                </p>
              )}
              <Markdown>{summary}</Markdown>
              <div className="d-flex align-items-center justify-content-end mt-2">
                <span
                  className="material-symbols-outlined md-18 me-2 cursor-pointer"
                  onClick={() => {
                    handleCopy(summary).then(() =>
                      toast.success(
                        "Success",
                        "Your content has been copied to the clipboard"
                      )
                    );
                  }}
                >
                  content_copy
                </span>
                <span
                  className="material-symbols-outlined md-18 cursor-pointer"
                  onClick={() => {
                    setQuery((prev) => ({
                      ...prev,
                      loadingPrompt: lastItem ? "" : query,
                    }));
                    fetchPromptAnswers(query, id, lastItem);
                    setTimeout(() => handleScrollToBottom(), 100);
                  }}
                >
                  restart_alt
                </span>
              </div>
            </div>
          </FilesAiInsightCard>
        </Fragment>
      );
    })
  );
};

export default FilesAiInsightsContentList;
