const formatFileNames = (arr) => {
  if (!Array.isArray(arr) || !arr.length) return "";

  if (arr.length === 1) return arr[0];
  if (arr.length === 2) return `${arr[0]} and ${arr[1]}`;

  const allButLastTwo = arr.slice(0, -2).join(", ");
  const secondToLast = arr[arr.length - 2];
  const lastOne = arr[arr.length - 1];

  return `${allButLastTwo}, ${secondToLast} and ${lastOne}`;
};

export { formatFileNames };
