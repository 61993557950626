import { useState, useEffect, useRef, useCallback } from "react";

import { get } from "utils/DeApi";

const useFetchComplianceMLValidation = ({
  observationId = "default",
  fetchByDefault = true,
}) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [complianceMLValidation, setComplianceMLValidation] = useState(null);

  const fetchComplianceMLValidation = useCallback(() => {
    if (observationId === "default") return;
    setIsLoading(true);

    const compliancePromise = get(
      `ml/observations/${observationId}/compliance/validate`
    );
    compliancePromise.promise
      .then((response) => {
        if (!compliancePromise.isCanceled) {
          setComplianceMLValidation(response);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(true);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(compliancePromise);
  }, [observationId]);

  useEffect(() => {
    if (fetchByDefault) fetchComplianceMLValidation();

    return () => {
      subscribedPromises.current.forEach(
        (promise) => promise.cancel && promise.cancel()
      );
      subscribedPromises.current = [];
    };
  }, [fetchComplianceMLValidation, fetchByDefault]);

  return {
    error,
    isLoading,
    complianceMLValidation,
    fetchComplianceMLValidation,
  };
};

export default useFetchComplianceMLValidation;
