import { useContext, useEffect, useState } from "react";
import { AuditContext } from "../contexts/AuditProvider";

const useVisibleReportObservations = () => {
  const { audit } = useContext(AuditContext);

  const [visibleQuestions, setVisibleQuestions] = useState({});
  const [visibleObservationQuestions, setVisibleObservationQuestions] =
    useState({
      Risk: true,
      Notes: true,
      Compliance: true,
      "Causal Factors": true,
      Recommendations: true,
      Conformity: true,
      "Corrective Action": true,
    });

  const handleToggleQuestionVisibility = (questionId) => {
    setVisibleQuestions((prev) => ({
      ...prev,
      [questionId]: !prev[questionId],
    }));
  };

  const handleToggleObservationQuestionVisibility = (questionType) => {
    setVisibleObservationQuestions((prev) => ({
      ...prev,
      [questionType]: !prev[questionType],
    }));
  };

  useEffect(() => {
    const initialVisibleQuestions = {};
    audit?.protocol?.flexibleQuestions?.forEach((question) => {
      initialVisibleQuestions[question.id] = true;
    });
    setVisibleQuestions(initialVisibleQuestions);
  }, []);

  return {
    visibleQuestions,
    visibleObservationQuestions,
    handleToggleQuestionVisibility,
    handleToggleObservationQuestionVisibility,
  };
};

export default useVisibleReportObservations;
