import { Fragment, useState, useEffect } from "react";

import Slider from "react-slick";
import { dateFilter } from "constants";
import { camelCase, head } from "lodash";
import { useFetchProtocols } from "features/organization";
import { principleChartFilters } from "constants";
import { Card, Col, Nav, Row, Tab, Tabs, Badge } from "react-bootstrap";

import Loader from "components/ui/Loader";
import { isArray } from "utils/ArrayUtils";
import { AuditList } from "features/assessment";
import { useActiveTab } from "features/entity/hooks";
import { settings } from "config/slickSliderConfigs";
import ErrorHandler from "components/ui/ErrorHandler";
import { PrinciplesSpiderWeb } from "features/assessment";
import { PrinciplesScoresGuage } from "features/assessment";
import { useFetchFacilityAudits } from "features/assessment";
import DateFilterDropdown from "components/ui/DateFilterDropdown";
import { useShowObservationProperties } from "features/assessment";
import ProtocolFilterDropdown from "components/ui/ProtocolFilterDropdown";
import PrincipleFilterDropdown from "components/ui/PrincipleFilterDropdown";
import { useFetchFacilityAnswerTypeAndDynamicFields } from "features/entity/services";
import {
  isCheckboxField,
  isNumericField,
  isRadioField,
} from "utils/fieldTypeUtil";

import FacilityStats from "./FacilityStats";
import FacilityActionItem from "./FacilityActionItem";
import FacilityReportRisk from "./FacilityReportRisk";
import FacilityBestPractices from "./FacilityBestPractices";
import FacilityReportCompliance from "./FacilityReportCompliance";
import FacilityReportConformity from "./FacilityReportConformity";
import FacilityPrincipleReportRisk from "./FacilityPrincipleReportRisk";
import FacilityPrincipleReportCompliance from "./FacilityPrincipleReportCompliance";
import FacilityPrincipleReportConformity from "./FacilityPrincipleReportConformity";
import RadioFieldPiechart from "components/common/RadioFieldPiechart";
import CheckboxFieldBarchart from "components/common/CheckboxFieldBarchart/CheckboxFieldBarchart";
import NumericFieldStats from "components/common/NumericFieldStats";
import FacilityMLSentimentAnalysis from "./FacilityMLSentimentAnalysis/FacilityMLSentimentAnalysis";

const FacilityOverview = ({ facility }) => {
  const [protocol, setProtocol] = useState();
  const [radioFields, setRadioFields] = useState();
  const [checkboxFields, setCheckboxFields] = useState();
  const [numericFields, setNumericFields] = useState();
  const [filterPeriod, setFilterPeriod] = useState(dateFilter[3]);
  const [showPrinciple, setShowPrinciple] = useState(principleChartFilters[0]);
  const [weightedScores, setWeightedScores] = useState([]);
  const [weightScoresKey, setWeightScoresKey] = useState("gauges-tab");
  const [protocolMismatchError, setProtocolMismatchError] = useState(false);

  const { key, setKey, setActiveTab } = useActiveTab();
  const { isRisk, isConformity, isCompliance } = useShowObservationProperties({
    protocol,
  });

  const isObservationsTrend = showPrinciple?.index === 1;
  const isObservationsByTopic = showPrinciple?.index === 2;
  const isFindingsAndBestPractice = showPrinciple?.index === 3;
  const isMLVisualization = showPrinciple?.index === 4;

  const {
    protocols,
    error: expandError,
    isLoading: isExpanding,
  } = useFetchProtocols({
    onSuccess: (protocols) => {
      const first = head(protocols);
      setProtocol(first || null);
      if (first) setActiveTab(first.questionOptions);
    },
  });

  const {
    error: bestPracError,
    isLoading: bestPracticeLoading,
    answerTypeStats: bestPracFindings,
    dynamicFieldStats,
  } = useFetchFacilityAnswerTypeAndDynamicFields({
    facilityId: facility?.facilityId,
    protocolId: protocol?.protocolId,
    isBestPracticeAndFindings: isFindingsAndBestPractice,
    onSuccess: (data = []) => {
      const dynamicFields = data;

      const fields = dynamicFields
        ?.filter(({ stats }) => stats.some(({ count }) => count > 0))
        .map((field) => ({
          ...field,
          show: true,
          name: field.fieldName,
          fieldType: field.fieldType,
          key: camelCase(field.fieldName),
        }));

      const radioCount = fields?.filter(({ fieldType }) =>
        isRadioField(fieldType)
      );

      const numericCount = fields?.filter(({ fieldType }) =>
        isNumericField(fieldType)
      );

      const checkboxCount = fields?.filter(({ fieldType }) =>
        isCheckboxField(fieldType)
      );

      const gauges = dynamicFields
        ?.filter(({ fieldType }) => isRadioField(fieldType))
        ?.filter((item) => Array.isArray(item?.gauges))
        ?.flatMap((item) =>
          item.gauges.map((guage) => ({
            ...guage,
            questionTitle: item?.fieldName,
          }))
        );

      setWeightedScores(gauges);
      setRadioFields(radioCount);
      setNumericFields(numericCount);
      setCheckboxFields(checkboxCount);

      if (!isRisk && !isCompliance && !isConformity) {
        if (!!radioCount?.length) {
          return setKey(radioCount[0]?.key);
        }

        if (!!checkboxCount?.length) {
          return setKey(checkboxCount[0]?.key);
        }
      }
    },
  });

  const { audits } = useFetchFacilityAudits({
    fetchByDefault: true,
    facilityId: facility.facilityId,
    protocol: protocol?.protocolId,
  });

  useEffect(() => {
    if (!protocol) return;
    const matchedAudits = audits.filter(
      (audit) => audit.protocol.protocolId === protocol.protocolId
    );

    setProtocolMismatchError(matchedAudits.length === 0);
  }, [audits, protocol]);

  return (
    <>
      <Col xs={12} sm={12} md={12} lg={12} xl={8}>
        <Row>
          <Col xs={12} className="mb-3">
            <Card className="px-3 pt-3">
              <Row>
                <div className="mb-4">
                  <h2 className="m-0">Overview</h2>
                </div>
                <Col md={12} xs={12} sm={12} lg={12}>
                  <FacilityStats facilityId={facility?.facilityId} />
                </Col>
                <hr />
                <Col xs={12} sm={12} md={5} lg={5} xl={5} className="mb-3">
                  <ProtocolFilterDropdown
                    selected={protocol}
                    isLoading={isExpanding}
                    options={protocols || []}
                    handleOnClick={(p) => {
                      setProtocol(p);
                      setActiveTab(p.questionOptions);
                      if (showPrinciple?.index === 4) {
                        setKey("ml-sentiment-analysis-tab");
                      }
                    }}
                  />
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4} className="mb-3">
                  <PrincipleFilterDropdown
                    selected={showPrinciple}
                    handleOnClick={(chart) => {
                      setShowPrinciple(chart);

                      if (chart?.index === 4) {
                        return setKey("ml-sentiment-analysis-tab");
                      }
                      if (isRisk) return setKey("risk-tab");
                      if (!isRisk && radioFields)
                        return setKey(radioFields[0]?.key);
                      if (!isRisk && checkboxFields)
                        return setKey(checkboxFields[0]?.key);
                    }}
                    filters={principleChartFilters}
                  />
                </Col>
                <Col xs={12} sm={12} md={3} lg={3} xl={3} className="mb-3">
                  {isObservationsTrend && (
                    <DateFilterDropdown
                      selected={filterPeriod}
                      handleOnClick={(interval) => setFilterPeriod(interval)}
                    />
                  )}
                </Col>
              </Row>

              {expandError && <ErrorHandler error={expandError} />}

              <Tab.Container
                id="left-tabs-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Nav variant="tabs" className="flex-row">
                  {[
                    {
                      key: "risk-tab",
                      show:
                        isRisk &&
                        !isFindingsAndBestPractice &&
                        !isMLVisualization,
                      name: protocol?.questionOptions[0]?.name || "Risk Rating",
                    },
                    {
                      key: "compliance-tab",
                      show:
                        isCompliance &&
                        !isFindingsAndBestPractice &&
                        !isMLVisualization,
                      name: protocol?.questionOptions[1]?.name || "Compliance",
                    },
                    {
                      key: "conformity-tab",
                      show:
                        isConformity &&
                        !isFindingsAndBestPractice &&
                        !isMLVisualization,
                      name: protocol?.questionOptions[2]?.name || "Conformity",
                    },
                    {
                      key: "weighted-scores-tab",
                      show:
                        !!weightedScores?.length &&
                        !isFindingsAndBestPractice &&
                        !isMLVisualization,
                      name: "Weighted Scores",
                    },
                    {
                      key: "ml-sentiment-analysis-tab",
                      show: isMLVisualization && !protocolMismatchError,
                      name: (
                        <>
                          Sentiment Analysis
                          <Badge className="text-info bg-info bg-opacity-10 ms-2">
                            <span>Beta</span>
                          </Badge>
                        </>
                      ),
                    },
                    ...(isArray(radioFields) && isObservationsTrend
                      ? radioFields
                      : []),
                    ...(isArray(checkboxFields) && isObservationsTrend
                      ? checkboxFields
                      : []),
                    ...(isArray(numericFields) && isObservationsTrend
                      ? numericFields
                      : []),
                  ].map((item) => {
                    if (!item.show) return <Fragment key={item?.key} />;
                    return (
                      <Nav.Item key={item?.key}>
                        <Nav.Link eventKey={item?.key}>{item?.name}</Nav.Link>
                      </Nav.Item>
                    );
                  })}
                </Nav>
                <Tab.Content className="pt-3">
                  {bestPracticeLoading &&
                    !isRisk &&
                    !dynamicFieldStats &&
                    !isFindingsAndBestPractice && <Loader />}
                  <Tab.Pane eventKey="risk-tab">
                    {isRisk && isObservationsTrend && (
                      <FacilityReportRisk
                        facility={facility}
                        protocol={protocol}
                        filterPeriod={filterPeriod}
                      />
                    )}
                    {isRisk && isObservationsByTopic && (
                      <FacilityPrincipleReportRisk
                        facility={facility}
                        protocol={protocol}
                        filterPeriod={filterPeriod}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="compliance-tab">
                    {isCompliance && isObservationsTrend && (
                      <FacilityReportCompliance
                        protocol={protocol}
                        facility={facility}
                        filterPeriod={filterPeriod}
                      />
                    )}
                    {isCompliance && isObservationsByTopic && (
                      <FacilityPrincipleReportCompliance
                        protocol={protocol}
                        facility={facility}
                        filterPeriod={filterPeriod}
                      />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="conformity-tab">
                    {isConformity && isObservationsTrend && (
                      <FacilityReportConformity
                        protocol={protocol}
                        facility={facility}
                        filterPeriod={filterPeriod}
                      />
                    )}
                    {isConformity && isObservationsByTopic && (
                      <FacilityPrincipleReportConformity
                        protocol={protocol}
                        facility={facility}
                        filterPeriod={filterPeriod}
                      />
                    )}
                  </Tab.Pane>
                  {!!weightedScores?.length && !isFindingsAndBestPractice && (
                    <Tab.Pane eventKey="weighted-scores-tab">
                      <Tabs
                        className="mb-3"
                        onSelect={(e) => setWeightScoresKey(e)}
                      >
                        <Tab
                          eventKey={`gauges-tab`}
                          title={"Scores By Principles (%) - Gauges"}
                        >
                          <Tabs className="mb-3">
                            {Object.entries(
                              Object.groupBy(
                                weightedScores || [],
                                ({ questionTitle }) => questionTitle
                              )
                            )?.map(([key, value]) => {
                              return (
                                <Tab
                                  key={`${key}`}
                                  eventKey={`${key}`}
                                  title={key}
                                >
                                  {!!(
                                    value?.length &&
                                    value?.reduce(
                                      (acc, val) => acc + Number(val?.maxScore),
                                      0
                                    )
                                  ) && (
                                    <Slider
                                      {...{
                                        ...settings,
                                        slidesToShow: 4,
                                        className: "pb-3",
                                      }}
                                    >
                                      {value?.map((data, index) => {
                                        const score =
                                          (data?.totalScore / data?.maxScore) *
                                          100;
                                        if (!isNaN(score)) {
                                          return (
                                            <PrinciplesScoresGuage
                                              title={`${data?.title}`}
                                              score={score?.toFixed(2) || 0}
                                              size="sm"
                                              key={index}
                                            />
                                          );
                                        } else {
                                          return false;
                                        }
                                      })}
                                    </Slider>
                                  )}
                                </Tab>
                              );
                            })}
                          </Tabs>
                        </Tab>
                        <Tab
                          eventKey={`spider-web-tab`}
                          title={"Scores By Principles - Spiderweb"}
                        >
                          <PrinciplesSpiderWeb
                            rerender={weightScoresKey}
                            overviewPage={true}
                            data={weightedScores.map((val) => ({
                              ...val,
                              flexibleQuestion: {
                                title: val?.questionTitle,
                              },
                              principle: {
                                principleId: val?.principleId,
                                name: val?.title,
                              },
                            }))}
                            principles={[
                              ...new Map(
                                (weightedScores || []).map((item) => [
                                  item["title"],
                                  item,
                                ])
                              ).values(),
                            ]}
                          />
                        </Tab>
                      </Tabs>
                    </Tab.Pane>
                  )}
                  {[
                    ...(isArray(radioFields) ? radioFields : []),
                    ...(isArray(checkboxFields) ? checkboxFields : []),
                    ...(isArray(numericFields) ? numericFields : []),
                  ].map((field) => {
                    const { key, fieldType } = field || {};
                    if (!isObservationsTrend)
                      return <Tab.Pane key={key} eventKey={key} />;

                    return (
                      <Tab.Pane key={key} eventKey={key}>
                        {isRadioField(fieldType) && (
                          <RadioFieldPiechart data={field} />
                        )}
                        {isCheckboxField(fieldType) && (
                          <CheckboxFieldBarchart data={field} />
                        )}
                        {isNumericField(fieldType) && (
                          <NumericFieldStats data={field} />
                        )}
                      </Tab.Pane>
                    );
                  })}

                  {isFindingsAndBestPractice && (
                    <FacilityBestPractices
                      protocol={protocol}
                      data={bestPracFindings}
                      isLoading={bestPracticeLoading}
                      error={bestPracError}
                    />
                  )}

                  {isMLVisualization && !protocolMismatchError && (
                    <Tab.Pane eventKey="ml-sentiment-analysis-tab">
                      <FacilityMLSentimentAnalysis
                        protocol={protocol}
                        setProtocol={setProtocol}
                        facilityId={facility?.facilityId}
                      />
                    </Tab.Pane>
                  )}
                </Tab.Content>
              </Tab.Container>
            </Card>
          </Col>
          <Col xs={12} className="mb-3">
            <AuditList />
          </Col>
        </Row>
      </Col>
      <Col xs={12} xl={4} className="mb-3">
        <FacilityActionItem facilityId={facility?.facilityId} />
      </Col>
    </>
  );
};

export default FacilityOverview;
