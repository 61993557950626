const suggestedPrompts = [
  {
    id: 1,
    prompt: "Explain hot work permit.",
  },
  {
    id: 2,
    prompt: "What is included in the onboarding documents for employees?",
  },
];

export default suggestedPrompts;
