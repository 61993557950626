import { useContext, useEffect, useState } from "react";
import { compare } from "../components/Audit/AuditDetails/helper";
import { AuditContext } from "../contexts/AuditProvider";

const useChartsState = ({ showInapplicable }) => {
  const { audit } = useContext(AuditContext);

  const questions = audit?.questions;
  const observations = questions.flatMap(
    (question) => question.observations || []
  );
  const responses = observations.flatMap(
    (observation) => observation.responses
  );

  const applicableObservations = observations
    .filter(({ isInapplicable }) => !isInapplicable)
    .map(({ observationId }) => observationId);

  const [riskPieChart, setRiskPieChart] = useState();
  const [conformityPieChart, setConformityPieChart] = useState();
  const [compliancePieChart, setCompliancePieChart] = useState();
  const [dynamicPieChart, setDynamicPieChart] = useState([]);

  const [chartsToBeToggledForExports, setChartsToBeToggledForExports] =
    useState([]);
  const [combinedConformityPieChart, setCombinedConformityPieChart] =
    useState();

  const [riskPrincipleBarChart, setRiskPrincipleBarChart] = useState();
  const [conformityPrincipleBarChart, setConformityPrincipleBarChart] =
    useState();

  const [spiderWebChart, setSpiderWebChart] = useState();
  const [hasSpiderWebChart, setHasSpiderWebChart] = useState();

  const [PrinciplesScoresGuageChart, setPrinciplesScoresGuageChart] = useState(
    []
  );
  const [hasPrinciplesScoreGuageChart, setHasPrinciplesScoreGuageChart] =
    useState();

  const [principles, setPrinciples] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const findSectionsAndPrinciples = () => {
      const sectionsPrinciples = [
        ...new Map(
          audit?.protocol?.sections
            .flatMap(({ principles }) => principles)
            .map((p) => [p?.principleId, p])
        ).values(),
      ];
      setPrinciples(sectionsPrinciples.sort(compare));
    };
    findSectionsAndPrinciples();
  }, [audit]);

  useEffect(() => {
    const determineShowSpiderWebandprincipleguage = (chartData) => {
      let toShowSpiderWeb = false;
      let toShowPrinciplesScoreGuage = false;
      chartData.forEach((field) => {
        if (
          !!responses.find(
            (item) =>
              item.flexibleQuestionId === field.id &&
              applicableObservations.includes(item.observationId)
          )
        )
          toShowSpiderWeb = true;
        toShowPrinciplesScoreGuage = true;
      });

      return { toShowSpiderWeb, toShowPrinciplesScoreGuage };
    };

    setHasSpiderWebChart(determineShowSpiderWebandprincipleguage(chartData));
    setHasPrinciplesScoreGuageChart(
      determineShowSpiderWebandprincipleguage(chartData)
    );
  }, [chartData]);

  useEffect(() => {
    const data = audit?.protocol?.showScoredFields
      ? principles
          ?.map((principle) => {
            return audit?.protocol?.flexibleQuestions
              ?.filter((flexibleQuestion) => flexibleQuestion.scoreable)
              ?.map((flexibleQuestion) => {
                const maxFlexibleQuestionScore =
                  flexibleQuestion?.choices?.reduce((acc, curr) => {
                    return curr?.score > acc ? curr?.score : acc;
                  }, 0);

                const avalableSections = audit?.protocol?.sections?.filter(
                  (section) => {
                    const rtn = section?.principles?.filter(
                      (sectionPrinciple) => {
                        return (
                          sectionPrinciple?.principleId ===
                          principle?.principleId
                        );
                      }
                    );
                    return rtn?.length;
                  }
                );
                const maxScore = avalableSections
                  ?.map((section) => {
                    return section?.questions?.reduce(
                      (acc, curr) =>
                        maxFlexibleQuestionScore * (curr?.weight || 0) + acc,
                      0
                    );
                  })
                  ?.reduce((acc, curr) => curr + acc, 0);

                const totalScore = responses?.reduce((acc, response) => {
                  const questionIsInThisPrinciple = avalableSections?.reduce(
                    (acc, section) => {
                      const answeredQuestion = section?.questions?.find(
                        (question) =>
                          question.questionId === response.questionId &&
                          flexibleQuestion?.id === response?.flexibleQuestionId
                      );
                      if (answeredQuestion) {
                        return { ...answeredQuestion };
                      } else {
                        return acc;
                      }
                    },
                    {}
                  );
                  if (Object.keys(questionIsInThisPrinciple)?.length) {
                    return (
                      acc +
                      (response?.choice?.score || 0) *
                        (questionIsInThisPrinciple?.weight || 0)
                    );
                  } else {
                    return acc;
                  }
                }, 0);

                const isInapplicable = responses?.findIndex(
                  (response) =>
                    response?.flexibleQuestionId === flexibleQuestion?.id &&
                    response?.isInapplicable
                );

                if (isInapplicable !== -1 && !showInapplicable) {
                  return null;
                }
                return {
                  id: flexibleQuestion.id,
                  maxScore: maxScore,
                  totalScore,
                  principle,
                  flexibleQuestion,
                };
              });
          })
          ?.reduce(
            (acc, curr) => [...acc, ...curr?.filter((item) => item !== null)],
            []
          )
      : [];
    const isDataAvailable = data?.reduce(
      (acc, curr) => curr?.totalScore + acc,
      0
    );

    if (!isDataAvailable) {
      setSpiderWebChart(null);
      setPrinciplesScoresGuageChart([]);
    }

    setChartData(data);
  }, [audit, principles, showInapplicable]);

  return {
    riskPieChart,
    setRiskPieChart,
    conformityPieChart,
    setConformityPieChart,
    compliancePieChart,
    setCompliancePieChart,
    dynamicPieChart,
    setDynamicPieChart,
    chartsToBeToggledForExports,
    setChartsToBeToggledForExports,
    combinedConformityPieChart,
    setCombinedConformityPieChart,
    riskPrincipleBarChart,
    setRiskPrincipleBarChart,
    conformityPrincipleBarChart,
    setConformityPrincipleBarChart,
    spiderWebChart,
    setSpiderWebChart,
    hasSpiderWebChart,
    setHasSpiderWebChart,
    PrinciplesScoresGuageChart,
    setPrinciplesScoresGuageChart,
    hasPrinciplesScoreGuageChart,
    setHasPrinciplesScoreGuageChart,
    principles,
    setPrinciples,
    chartData,
    setChartData,
  };
};

export default useChartsState;
