import { useCallback, useEffect, useRef, useState } from "react";

import { get } from "utils/DeApi";

const useFetchConformityMLValidation = ({
  observationId = "default",
  fetchByDefault = false,
}) => {
  const subscribedPromises = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [conformityMLValidation, setConformityMLValidation] = useState(null);

  const fetchMLConformityValidation = useCallback(() => {
    if (observationId === "default") return;

    setIsLoading(true);
    setError(false);

    const conformityLevelPromise = get(
      `ml/observations/${observationId}/conformity-levels/validate`
    );

    conformityLevelPromise.promise
      .then((response) => {
        if (!conformityLevelPromise.isCanceled) {
          setConformityMLValidation(response);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(true);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(conformityLevelPromise);
  }, [observationId]);

  useEffect(() => {
    if (fetchByDefault) fetchMLConformityValidation();
    return () => {
      subscribedPromises.current.forEach(
        (promise) => promise.cancel && promise.cancel()
      );
      subscribedPromises.current = [];
    };
  }, [fetchMLConformityValidation, fetchByDefault]);

  return {
    error,
    isLoading,
    conformityMLValidation,
    fetchMLConformityValidation,
  };
};

export default useFetchConformityMLValidation;
