import * as yup from "yup";

const actionItemValidationSchema = yup.object().shape({
  item: yup
    .string()
    .min(2, "Text is too Short!")
    .max(255, "Text is too Long!")
    .required("This field is required"),
  description: yup.string().nullable().max(65000, "Description is too Long!"),
  assignedTo: yup.array().min(1).required(),
  dueDate: yup.date().required("Due date is a required field"),
});

export default actionItemValidationSchema;
