export const badgeClass = (sentiment, percentage) => {
  const parsedPercentage = parseFloat(percentage?.replace("%", "") || 0);
  if (sentiment === "negative") {
    if (parsedPercentage > 75) return "text-white bg-danger";
    if (parsedPercentage > 50) return "text-danger bg-danger bg-opacity-25";
    if (parsedPercentage <= 50) return "text-danger bg-danger bg-opacity-10";
  }
  if (parsedPercentage > 75) return "text-white bg-primary";
  if (parsedPercentage > 50) return "text-primary bg-primary bg-opacity-25";
  if (parsedPercentage <= 50) return "text-primary bg-primary bg-opacity-10";
};

export const iconClass = (sentiment, percentage) => {
  const parsedPercentage = parseFloat(percentage?.replace("%", "") || 0);
  if (sentiment === "negative") {
    return parsedPercentage > 75 ? "text-white" : "text-danger";
  }
  return parsedPercentage > 75 ? "text-white" : "text-primary";
};

export const iconType = (sentiment) => {
  return sentiment === "negative" ? "thumb_down" : "thumb_up";
};
