import * as yup from "yup";

const actionItemBulkUpdateValidationSchema = yup.object().shape({
  assignedTo: yup
    .array()
    .min(1, "Assigned To field must have atleast one subscriber")
    .required(),
  dueDate: yup.date(),
});

export default actionItemBulkUpdateValidationSchema;
