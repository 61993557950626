import { useToast } from "hooks";
import { createContext, useReducer } from "react";
import { useVertical } from "../hooks";

import _ from "lodash";
import useChartsState from "../hooks/useChartsState";
import useVisibleReportObservations from "../hooks/useVisibleReportObservations";

export const ReportContext = createContext({
  state: {},
  dispatch: () => {},
  chartState: {},
  observationVisibilty: {},
});

const initialState = {
  showCharts: false,
  executiveSummary: false,
  showMetadata: false,
  summaryTable: false,
  showAttachments: false,
  showCitations: false,
  showInapplicable: false,
  showRiskMatrices: false,
  view: "list",
  showSignatories: false,
  showLegalDisclaimer: false,
  showEmptyFlexibleQuestions: false,
};

function convertConstantToCamelCase(input) {
  // Remove the "SET_" prefix and convert the rest to camelCase
  return _.camelCase(input.replace(/^SET_/, ""));
}

const reducer = (state, action) => {
  const validActions = Object.keys(initialState);

  if (!validActions.includes(convertConstantToCamelCase(action.type))) {
    throw new Error(
      `Invalid action type: ${
        action.type
      }. Allowed action types are: ${validActions
        .map((key) => `SET_${_.snakeCase(key).toUpperCase()}`)
        .join(", ")}`
    );
  }
  return {
    ...state,
    [convertConstantToCamelCase(action.type)]: action.payload,
  };
};

const ReportProvider = ({ children }) => {
  const { defaultViewInReport } = useVertical();
  const toast = useToast();

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    view: defaultViewInReport,
  });
  const chartState = useChartsState({
    showInapplicable: state.showInapplicable,
  });

  const observationVisibilty = useVisibleReportObservations();

  const safeDispatch = (action) => {
    try {
      dispatch(action);
    } catch (error) {
      toast.error("error", error.message);
    }
  };

  return (
    <ReportContext.Provider
      value={{
        state,
        dispatch: safeDispatch,
        chartState,
        observationVisibilty,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

export default ReportProvider;
