import { principleChartFilters } from "constants";
import { truncate } from "lodash";
import { Dropdown } from "react-bootstrap";

const PrincipleFilterDropdown = ({ selected, handleOnClick, filters }) => {
  return (
    <Dropdown translate="no">
      <Dropdown.Toggle
        variant="outline-secondary"
        id="time-interval-filter"
        className="border btn-block w-100 text-start"
        size="sm"
      >
        <span translate="no" className="material-symbols-outlined md-18 me-2">
          date_range
        </span>
        {truncate(selected?.text || "")}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {filters.map((interval) => (
          <Dropdown.Item
            key={interval.index}
            active={interval.index === selected.index}
            onClick={() => {
              if (typeof handleOnClick === "function") handleOnClick(interval);
            }}
          >
            {interval.text}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default PrincipleFilterDropdown;
