import { Fragment } from "react";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import EmptyStateHandler from "components/ui/EmptyStateHandler";

import FileLink from "./FileLink";
import FileUpdate from "../FileUpdate";
import FileDelete from "../FileDelete";
import FileDownload from "../FileDownload";
import FileCollapseView from "../FileCollapseView";
import { ImagePreviewModal } from "features/files";

export default function FileListItems({
  isLoading,
  error,
  files,
  setFiles,
  expandableId,
  setExpandableId,
}) {
  const handleExpandleId = (id) => setExpandableId(id);

  const onFileUpdate = (updatedFile) => {
    setFiles((prevFiles) => {
      return prevFiles.map((file) => {
        if (updatedFile?.id === file?.id) return updatedFile;
        return file;
      });
    });
  };

  const onFileDelete = (deletedFile) => {
    setFiles((prevFiles) => {
      return prevFiles.filter((file) => {
        return deletedFile?.id !== file?.id;
      });
    });
  };

  if (isLoading)
    return (
      <tr>
        <td colSpan={8}>
          <Loader />
        </td>
      </tr>
    );

  if (error)
    return (
      <tr>
        <td colSpan={8}>
          <ErrorHandler error={error} />
        </td>
      </tr>
    );

  if (!Array.isArray(files)) return <></>;

  if (!files.length)
    return (
      <tr>
        <td colSpan={8}>
          <EmptyStateHandler
            className="mb-3 d-flex flex-row"
            title="No Files Found"
            description="Files will appear here once added"
          />
        </td>
      </tr>
    );

  return (
    <>
      {files.map((file) => {
        const { id, description, modelType, updatedAt } = file;

        return (
          <Fragment key={id}>
            <tr>
              <td className="cursor-pointer">
                {!(expandableId === id) ? (
                  <span
                    translate="no"
                    className="material-symbols-outlined md-20"
                    onClick={() => handleExpandleId(id)}
                  >
                    expand_more
                  </span>
                ) : (
                  <span
                    translate="no"
                    onClick={() => handleExpandleId("")}
                    className="material-symbols-outlined md-20"
                  >
                    expand_less
                  </span>
                )}
              </td>
              <td className="text-wrap text-break">
                <FileLink file={file} />
              </td>
              <td className="text-wrap text-break">{description}</td>
              <td className="text-capitalize">
                {modelType === "facility" ? "entity" : modelType}
              </td>
              <td>
                <small>
                  {new Date(updatedAt).toLocaleString([], {
                    dateStyle: "short",
                    timeStyle: "short",
                  })}
                </small>
              </td>
              <td className="text-nowrap text-end">
                <ImagePreviewModal file={file} />
                <FileDownload file={file} />
                <FileUpdate file={file} onFileUpdate={onFileUpdate} />
                <FileDelete file={file} onFileDelete={onFileDelete} />
              </td>
            </tr>
            <FileCollapseView file={file} expandableId={expandableId} />
          </Fragment>
        );
      })}
    </>
  );
}
