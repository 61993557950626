import React, { useState } from "react";

import PropTypes from "prop-types";

import ErrorHandler from "components/ui/ErrorHandler";
import EmptyStateHandler from "components/ui/EmptyStateHandler";

import {
  ActionItemAssignToField,
  ActionItemDescriptionField,
  ActionItemDueDateField,
  ActionItemTagsField,
  ActionItemTitleField,
  ActionItemTypeField,
} from "../../ActionItemForm";
import ActionItemStatusField from "../../ActionItemForm/ActionItemStatusField";

const ActionItemUpdateGeneralInfo = ({
  show,
  taskableType,
  taskableId,
  facilityId,
  children,
}) => {
  const [error, setError] = useState();

  return (
    <>
      <ActionItemStatusField />
      <ActionItemTitleField ref={null} />
      <ActionItemTypeField taskableType={taskableType} />
      {children}
      <ActionItemDescriptionField />
      <ActionItemDueDateField />
      <ActionItemAssignToField
        show={show}
        taskableType={taskableType}
        taskableId={taskableId}
        facilityId={facilityId}
        setError={setError}
      />
      <ActionItemTagsField show={show} setError={setError} />

      {taskableType === "question" && !facilityId && (
        <EmptyStateHandler
          title="No Assessment"
          description="The action item does not have an associated assessment"
        />
      )}
      {error && show && <ErrorHandler error={error} />}
    </>
  );
};

ActionItemUpdateGeneralInfo.propTypes = {
  show: PropTypes.bool,
  taskableType: PropTypes.string,
  taskableId: PropTypes.string,
  facilityId: PropTypes.string,
};

export default ActionItemUpdateGeneralInfo;
