import { OrganizationContext } from "contexts/OrganizationProvider";
import { saveAs } from "file-saver";
import { useContext } from "react";
import { useReactToPrint } from "react-to-print";
import { fileConvert } from "utils/BeeApi";
import { AuditContext } from "../contexts/AuditProvider";
import useVertical from "./useVertical";

const useReportExport = ({ toPrintRef, setDownloadingPDF, setError }) => {
  const organization = useContext(OrganizationContext);
  const { audit } = useContext(AuditContext);
  const { generatedFileNameShouldOnlyHaveAssessmentName } = useVertical();

  const formattedDate =
    new Intl.DateTimeFormat("en-US", { month: "short" }).format(new Date()) +
    " " +
    new Date().getDate() +
    " " +
    new Date().getFullYear();
  var formattedTime = new Intl.DateTimeFormat("en-US", {
    timeStyle: "short",
  }).format(new Date());

  const handlePrint = useReactToPrint({
    content: () => toPrintRef.current,
  });

  const downloadAudit = (htmlFile) => {
    const formData = new FormData();

    formData.append("html_file", htmlFile);
    formData.append("file_name", "audit");
    formData.append(
      "file_name",
      `${organization.name} ${audit.name} Assessment Report`
    );
    formData.append(
      "header_html",
      `<div style="width: 100%; visibility:hidden; margin-bottom: 2px; border-top: 1px solid seagreen; display:flex;flex-direction:row;justify-content:space-between"><p style="text-align:left; font-size: 7px; color: green;margin-left:2px">${audit.name}</p><p style="text-align:right; font-size: 7px; color: green;margin-right:2px">${formattedTime}</p></div>`
    );
    formData.append(
      "footer_html",
      `<div style="width: 100%;margin-top: 8px; visibility:hidden; border-bottom: 1px solid seagreen; display:flex;flex-direction:row;justify-content:space-between"><p style="text-align:left; font-size: 7px; color: green;margin-left:2px">${organization.name}</p><p style="text-align:right; font-size: 7px; color: green;margin-right:2px">${formattedDate}</p></div>`
    );
    formData.append("header_and_footer", 1);
    const auditPromise = fileConvert(`/services/html-to-pdf`, formData);
    auditPromise.promise
      .then((response) => {
        const pdfFile = new Blob([response.data], {
          type: "text/html;charset=UTF-8",
        });
        const filename = generatedFileNameShouldOnlyHaveAssessmentName
          ? `${audit.name}.pdf`
          : `${organization.name} ${audit.name} Assessment Report.pdf`;
        saveAs(pdfFile, filename);
        setDownloadingPDF(false);
      })
      .catch((error) => {
        setDownloadingPDF(false);
        setError(error);
        console.warn(error);
      });
  };

  const handlePDFDownload = useReactToPrint({
    content: () => toPrintRef.current,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;

      const origin = window.location.origin.includes("localhost")
        ? "https://sandbox.ermassess.com"
        : window.location.origin;

      setDownloadingPDF(true);

      if (document) {
        var base = document.createElement("base");
        base.href = origin + "/";
        document.getElementsByTagName("head")[0].appendChild(base);

        var head = document.getElementsByTagName("head")[0];
        var links = head.getElementsByTagName("link");

        for (const link in links) {
          if (links.hasOwnProperty(link)) {
            var l = links[link];
            if (l.rel === "stylesheet") {
              l.href = "" + l.href;
            }
          }
        }

        const html = document.getElementsByTagName("html")[0];
        const htmlFile = new Blob([html.outerHTML], {
          type: "text/html;charset=UTF-8",
        });
        downloadAudit(htmlFile);
      } else {
        console.warn("Printing Error");
        setDownloadingPDF(false);
      }
    },
  });

  return {
    handlePrint,
    handlePDFDownload,
  };
};

export default useReportExport;
