import * as yup from "yup";
import { MAX_FILE_SIZE } from "features/assessment/constants";
import { SUPPORTED_FORMATS } from "utils/UploadUtils";

const fileBulkUploadValidationSchema = (isMember) =>
  yup.object().shape({
    description: yup
      .string()
      .required("The Description is required")
      .min(3, "Description is too Short!")
      .max(65000, "Description is too Long!"),
    files: yup
      .array()
      .min(1, "The file is required")
      .max(30, "You can upload a maximum of 30 files")
      .required("The file is required")
      .test("max-fileSize", "File Size is too large", (value) => {
        if (value && value?.length > 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i].size > MAX_FILE_SIZE) {
              return false;
            }
          }
        }
        return true;
      })
      .test("fileFormat", "Unsupported Format", (value) => {
        if (!!value.length) {
          return value.every((file) => {
            const fileEXT = file?.path.split(".").pop().trim().toLowerCase();
            return SUPPORTED_FORMATS.includes(`.${fileEXT}`);
          });
        }
      }),
    entity: yup
      .array()
      .test("required", "The Entity is required", (value, context) => {
        if (isMember) {
          return value?.length;
        } else {
          return true;
        }
      }),
  });

export default fileBulkUploadValidationSchema;
