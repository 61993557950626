import React, { useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import "./FilesAiInsights.scss";
import { suggestedPrompts } from "features/files/constants";
import { useFetchPromptAnswers } from "features/files/services";
import ErrorHandler from "components/ui/ErrorHandler";
import InsightsIcon from "./InsightsIcon";
import FilesAiInsightCard from "./FilesAiInsightCard";
import FilesAiInsightsContentList from "./FilesAiInsightsContentList";

const FilesAiInsights = () => {
  const [show, setShow] = useState(false);

  const [query, setQuery] = useState({});

  const { prompt, loadingPrompt } = query || {};

  const {
    isLoading,
    error,
    fetchPromptAnswers,
    promptAnswers,
    setPromptAnswers,
    setError,
  } = useFetchPromptAnswers();

  const handleShow = () => setShow(true);

  const onReset = () => {
    setPromptAnswers([]);
    setQuery({});
    setError(null);
  };
  const handleClose = () => {
    setShow(false);
    setTimeout(() => onReset(), 100);
  };

  const handleScrollToBottom = () => {
    const offcanvasBody = document.querySelector(".offcanvas-body");
    if (!offcanvasBody) return;
    offcanvasBody.scrollTo({
      top: offcanvasBody.scrollHeight,
      behavior: "smooth",
    });
  };

  const onQuerySearch = () => {
    if (!prompt) return;
    fetchPromptAnswers(prompt);
    setQuery((prev) => ({ ...prev, loadingPrompt: prompt, prompt: "" }));
    setTimeout(() => handleScrollToBottom(), 100);
  };

  return (
    <>
      <Button
        className="me-2 d-flex align-items-center py-1 insights-button"
        title="AI Insights"
        size="sm"
        variant="outline-primary"
        onClick={handleShow}
      >
        <InsightsIcon className="m-1 me-2" fill={""} />
        AI Insights
      </Button>

      <Offcanvas show={show} placement="end" className="w-fixed-640">
        <Offcanvas.Header
          onHide={handleClose}
          className="border-bottom"
          closeButton
        >
          <Offcanvas.Title>AI Insights</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="h-calc-120">
          {!loadingPrompt && !promptAnswers.length && (
            <div>
              <h6 className="text-dark mb-2">Suggestions</h6>
              <div className="d-flex flex-wrap gap-2">
                {suggestedPrompts.map(({ id, prompt }) => (
                  <Button
                    className="p-2 px-3 fs-6 fw-semibold rounded"
                    variant="outline-primary"
                    key={id}
                    onClick={() => {
                      setQuery((prev) => ({ ...prev, loadingPrompt: prompt }));
                      fetchPromptAnswers(prompt);
                    }}
                  >
                    {prompt}
                  </Button>
                ))}
              </div>
            </div>
          )}
          <FilesAiInsightsContentList
            promptAnswers={promptAnswers}
            fetchPromptAnswers={fetchPromptAnswers}
            setQuery={setQuery}
          />
          {isLoading && (
            <FilesAiInsightCard
              query={loadingPrompt}
              className="align-items-center"
            >
              <div className="px-3 py-2 rounded fs-6">
                <span className="insights-loader text-primary" />
              </div>
            </FilesAiInsightCard>
          )}
          {show && error && <ErrorHandler error={error} />}
        </Offcanvas.Body>
        <div className="sticky-bottom p-3 bg-light">
          <div className="input-group mb-3">
            <Form.Control
              type="text"
              id="prompt"
              aria-describedby="prompt"
              placeholder="Enter your query"
              value={prompt}
              onChange={(e) => {
                setQuery((prev) => ({
                  ...prev,
                  prompt: e.target.value,
                }));
              }}
              onKeyDown={(e) => e.key === "Enter" && onQuerySearch()}
            />
            <Button
              variant="outline-secondary"
              className="form-control search-icon-button px-2 cursor-pointer"
              onClick={onQuerySearch}
              disabled={!prompt}
            >
              <span className="material-symbols-outlined md-18 text-secondary">
                search
              </span>
            </Button>
          </div>
          <p className="text-center fs-6 mb-0">
            <small className="text-muted">
              The information provided by AI Insights is based on the provided
              files.
              <br />
              View the anlayzed files and limitations
            </small>
          </p>
        </div>
      </Offcanvas>
    </>
  );
};

export default FilesAiInsights;
