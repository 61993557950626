import React from "react";

import { useRoles } from "hooks";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import Loader from "components/ui/Loader";
import ErrorHandler from "components/ui/ErrorHandler";
import EmptyStateHandler from "components/ui/EmptyStateHandler";
import OverlayTriggerButton from "components/ui/OverlayTriggerButton";
import AuditLabels from "features/assessment/components/ui/AuditLabels";
import AchivedBadge from "features/assessment/components/ui/AchievedBadge";
import AuditUpdate from "features/assessment/components/common/AuditUpdate";
import AuditDelete from "features/assessment/components/common/AuditDelete";
import AuditLockedBadge from "features/assessment/components/ui/AuditLockedBadge";
import AuditStatusBadge from "features/assessment/components/ui/AuditStatusBadge";

import AuditHistory from "./AuditHistory/AuditHistory";
import { truncate } from "lodash";

const AuditListItem = ({ error, isLoading, audits, setAudits }) => {
  const { isContributor } = useRoles();

  const handleAuditUpdated = (updatedAudit) => {
    setAudits((prevAudits) =>
      prevAudits.map((audit) => {
        if (audit?.auditId === updatedAudit?.auditId)
          return {
            ...audit,
            ...updatedAudit,
          };
        return audit;
      })
    );
  };

  const handleAuditDeleted = (deletedAudit) => {
    setAudits((prevAudits) =>
      prevAudits.filter(({ auditId }) => auditId !== deletedAudit?.auditId)
    );
  };

  if (isLoading)
    return (
      <tr>
        <td colSpan={8}>
          <Loader />
        </td>
      </tr>
    );

  if (error)
    return (
      <tr>
        <td colSpan={8}>
          <ErrorHandler error={error} />
        </td>
      </tr>
    );

  if (!audits) return <></>;

  if (!audits.length)
    return (
      <tr>
        <td colSpan={8}>
          <EmptyStateHandler
            title="No assessments found"
            description="There are currently no assessments to show"
          />
        </td>
      </tr>
    );

  return audits.map((audit) => (
    <tr key={audit?.auditId}>
      <td>
        <Link
          to={`/audits/${audit?.auditId}`}
          size="sm"
          className="text-decoration-none bold"
          variant="secondary"
        >
          {audit?.name}
        </Link>
        <br />
        <AuditLabels labels={audit?.labels} />
        <AchivedBadge achieved={audit?.achieved} />
        <AuditLockedBadge locked={audit?.locked} />
        <AuditStatusBadge status={audit?.status} />
      </td>
      <td className="truncate">
        <small>{audit?.protocol?.name}</small>
      </td>
      <td title={audit?.group?.title || "-"}>
        <small>
          {truncate(audit?.group?.title || "-", {
            length: 25,
          }).replace(/ /g, "\u00A0")}
        </small>
      </td>
      <td>
        <small>
          {new Date(audit?.updatedAt).toLocaleString([], {
            timeZone: "UTC",
            dateStyle: "short",
            timeStyle: "short",
          })}
        </small>
      </td>
      <td className="text-end">
        <div className="d-flex flex-row">
          <div className="me-2">
            <AuditHistory audit={audit} />
          </div>
          <div className="me-2">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="reports-tooltip">Reports</Tooltip>}
            >
              <Button
                as={Link}
                to={`/audits/${audit?.auditId}/reports`}
                size="sm"
                variant="outline-secondary"
                className="position-relative"
              >
                <span
                  translate="no"
                  className="material-symbols-outlined md-16"
                >
                  description
                </span>
                {!!audit.observationReportCommentsCount && (
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {audit?.observationReportCommentsCount}
                    <span className="visually-hidden">unread messages</span>
                  </span>
                )}
              </Button>
            </OverlayTrigger>
          </div>
          {!isContributor && (
            <>
              <div className="me-2">
                <AuditUpdate
                  audit={audit}
                  onAuditUpdated={handleAuditUpdated}
                  children={
                    <OverlayTriggerButton
                      placement="top"
                      toolTipMessage="Edit"
                      variant="outline-primary"
                      label={
                        <span
                          translate="no"
                          className="material-symbols-outlined md-16"
                        >
                          edit
                        </span>
                      }
                    />
                  }
                />
              </div>
              <div className="me-2">
                <AuditDelete
                  audit={audit}
                  onAuditDeleted={handleAuditDeleted}
                  children={
                    <OverlayTriggerButton
                      placement="top"
                      toolTipMessage="Delete"
                      variant="outline-danger"
                      label={
                        <span
                          translate="no"
                          className="material-symbols-outlined md-16"
                        >
                          delete
                        </span>
                      }
                    />
                  }
                />
              </div>
            </>
          )}
        </div>
      </td>
    </tr>
  ));
};

AuditListItem.propTypes = {
  error: PropTypes.any,
  isLoading: PropTypes.bool,
  audits: PropTypes.array.isRequired,
  setAudits: PropTypes.func.isRequired,
};

export default AuditListItem;
