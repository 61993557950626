const principleChartFilters = [
  {
    index: 1,
    text: "Observations Trend",
  },
  {
    index: 2,
    text: "Observations by Topic",
  },
  {
    index: 3,
    text: "Findings and Best Practices",
  },
  {
    index: 4,
    text: "ML Visualizations",
  },
];

export default principleChartFilters;
