import { useContext, useMemo } from "react";
import { AuditContext } from "../contexts/AuditProvider";

const useExtractMLObservationData = (observationId) => {
  const { audit } = useContext(AuditContext);

  const extractMLObservationData = useMemo(() => {
    if (observationId === "default" || !audit || !audit.protocol) return {};

    const observations = audit.questions.flatMap(
      ({ observations = [] }) => observations
    );

    const observation = observations?.find(
      (obs) => obs.observationId === observationId
    );

    if (!observation) return {};

    const notesValue =
      observation?.responses?.find(
        (response) =>
          response.flexibleQuestion?.questionType === "Text Field" &&
          response.flexibleQuestion?.prompt
      )?.notes || "";

    const conformityLevel =
      observation?.responses?.find(
        (response) =>
          response.flexibleQuestion?.prompt.toLowerCase() === "conformity level"
      )?.choice?.label || "";

    const mlAnalysisData = audit?.observations?.find(
      (obs) => obs.observationId === observationId
    )?.mlAnalysis;
    const isDone = audit?.status === "Done";

    const complianceMLValidation = mlAnalysisData?.compliance;
    const conformityMLValidation = mlAnalysisData?.conformity;

    return {
      notesValue,
      conformityLevel,
      isDone,
      observation,
      audit,
      complianceMLValidation,
      conformityMLValidation,
    };
  }, [observationId]);

  return extractMLObservationData;
};

export default useExtractMLObservationData;
