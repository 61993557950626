import { useState } from "react";
import useToast from "./useToast";

const useCopyToClipboard = () => {
  const toast = useToast();
  const [error, setError] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const copyTextToClipboard = async (text) => {
    return await navigator?.clipboard?.writeText(text);
  };

  const handleCopy = (text) => {
    return copyTextToClipboard(text)
      .then(() => setIsCopied(true))
      .catch((err) => {
        setError(err);
        setIsCopied(false);
        toast.error("Error", "Failed to copy to clipboard");
      });
  };

  return { isCopied, error, handleCopy, setIsCopied };
};

export default useCopyToClipboard;
